/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App header h1 {
  font-size: 1em;
  margin: 0;
}

.App-header {
  background-color: #000;
  min-height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
  flex-grow: 1;
  text-align: center;
  padding: 25px;
}
footer {
  background-color: #000;
  min-height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
}

footer span {
  margin: 0 6px 0 3px;
}

header {
  padding: 25px;
}

li {
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

li.strong {
  font-weight: 700;
  font-size: 1.1em;
  margin: 10px;
}

.card {
  box-shadow: 5px 5px 8px -5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  border: 1px solid #d3d3d3;
  transform: translateY(-5px);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  box-shadow: 0 0 #000;
  outline: none;
  border: none;
  transition: all 0.4s ease-in-out;
  font-weight: 500;
}

.btn-primary:hover {
  color: lightgreen;
  background: #282c34;
}

.form-control:focus,
.btn-primary:visited {
  color: #495057;
  background-color: #fff;
  border-color: #4aee21bd;
  outline: 0;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  color: lightgreen;
  background: #282c34;
  border-color: #4aee21bd;
  box-shadow: none;
}

.weatherContainer h3 {
  margin-bottom: 3%;
  background: #000;
  color: #fff;
  padding: 1%;
  border-radius: 10px;
}

.card.loading {
  background-color: #dedede49;
  animation-name: loading;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-webkit-keyframes loading {
  0% {
    background-color: #dedede;
  }
  50% {
    background-color: #dedede49;
  }
  100% {
    background-color: #dedede;
  }
}

@keyframes loading {
  0% {
    background-color: #dedede;
  }
  50% {
    background-color: #dedede49;
  }
  100% {
    background-color: #dedede;
  }
}

section.weatherContainer > div {
  margin-bottom: 5% !important;
}
